import { useState, ChangeEvent, FormEvent, useEffect} from 'react'

import Swal from 'sweetalert2'
import { useParams } from 'react-router';
import Select from 'react-select'
import axios from 'axios';
import { API_URL, config, CLOUD_IMAGES } from '../components/Include';
import { Alerts, ErrorModal, LoadingModal, closeModal } from '../components/notify';

import TextEditor from './Editor'
import { convertDateTime, styles } from '../components/GlobalFunction';


const UpdateNews =()=> {

  let params =  useParams();
  const [staff, setStaff] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [category, setCategory] = useState([] as any);
  const [news, setNews] = useState([] as any);
  const [image, setImage] = useState('');
  const [content, setContent] = useState({
    
  cat_code:'',
  file_path:'',
  file_url:'',
  image_code:'',
  image_url:'',
  image_caption:'',
  passport:'',
  similar_news:[] as any,
  tag:[] as any
  });
  const [items, setItems] = useState([] as any)

	const [post, setPost] = useState([] as any)


    const [errors, setErrors] = useState({
      author:'',
      title:'',	
      category:'',	
      content:'',	
      slug:'',
      image_url:'',
      successMessage:'',
      infoMessage:'',
      errorMessage:'',
      selectImage:''
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setPost({...post, [name]:value});
     setErrors({...errors, [name]:''});
   }

   
   const handleSelectEdit = (option:any, action:any)=>{
    setContent({...content, [action.name]:option});
    setErrors({...errors, [action.name]:''});
       }

       const DeleteModal =()=>{

        let url = API_URL+'/news/delete/'+params.code;
      
        Swal.fire({
          title: 'Are you really sure?',
          text: "This is very dangerous, you shouldn't do it!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showLoaderOnConfirm: true,
        preConfirm: async() => {
          return await axios.get(url, config).then(response=>{
           if(response.data.status!==200){
                throw new Error(response.statusText)
              }
              return response.data
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
          confirmButtonText: 'Yes, I am!'
        }).then((result:any) => {
          if (result.isConfirmed && result.value.status===200) {
             Swal.fire(
                        'Deleted!',
                        'Record has been deleted.',
                        'success'
                      ) 

                window.open('/news', "_self")
          }
        })
      }

  const handleSelect = (option:any, action:any)=>{
    setPost({...post, [action.name]:option.value});
    setErrors({...errors, [action.name]:''});
       }

       const handleSwitch =(name:string, value:string)=>{
        value = value==='Yes'?'No':'Yes'
        setPost({...post, [name]:value});
        }

        const handlePaste = () => {
          navigator.clipboard.readText().then((text) =>setPost({...post,content:text}))
         setErrors({...errors, content:''})
         }

  const handleEditorChange = (content:any, editor:any) => {
	
    setPost({...post, content:content});
	  }

const getSimilar =()=>{

  if(post.length!==0){

  let similar = post.similar_news
  let tag = post.tags

setContent({...content, 
  similar_news:getNews(similar, news), 
  tag:getNews(tag, tags)})
  }
}

    const fetchPost = async()=>{
      LoadingModal()
      let url = API_URL+'/news/show/'+params.code
        await  axios.get(url,  config).then((result:any)=>{
         
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setItems(result.data.data)
          setPost(result.data.data[0])


          }else{

            ErrorModal('404', ' No record found')
            setTimeout(() => {
              window.open("/news", "_self")
            }, 2000);
          }
          })
          .catch((error)=>{
            
            //ErrorModal('oops', error.message)
          }).finally(()=>{
            closeModal()
          })
        }

    const fetchContent = async()=>{
      let url = API_URL+'/staff/news/category'
        await  axios.get(url,  config).then((result:any)=>{
          if(Array.isArray(result.data.staff)){
          setStaff(result.data.staff)
          setNews(result.data.news)
          setTags(result.data.tags)
          setCategory(result.data.category)
          
          }
          })
          .catch((error)=>{
            //ErrorModal('oops', error.message)
          })
        }
  


const  handleFile = function(fieldName:any){
  return function(newValue:any){                 
 readURL(newValue);
 }
} 
const readURL = (input:any)=>{

  let displayMessage = '';
  
  const PreviewImage = input.target.name;
  const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
  const fileName = inputElement.value
  
  var idxDot = fileName.lastIndexOf(".") + 1;
  var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
  
  if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
      //TO DO    
      
       var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
  if (FileSize > 3) {
      displayMessage = ' File uploaded is larger than maximum size Allow (3 mb).';
      inputElement.value = '';
   
      setImage('')
      setContent({...content, passport:'', image_url:'', file_path:'', file_url:''})
  } else {
      if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e:any) {
            setContent({...content, passport:e.target.result, image_url:'imgd.png', file_path:'', file_url:''})
            
          //Check if input has files.
           setImage(input.target.files[0])
           setErrors({...errors, image_url:''})
          }
          reader.readAsDataURL(input.target.files[0]);
          
      }
  }
  }else{
  displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 3 mb'
  inputElement.value = '';
  setImage('')
  }   
  
  if(displayMessage.length > 0){

    Alerts('danger', displayMessage, 'Error')
  }
  }



        const getCategory =(code:string)=>{
          let rs = category&&category.filter((item:any)=>item.code===code)
          let ans = rs.length!==0?rs[0].category_name:''
          return ans
        }

        const getStaff =(code:string)=>{
          let rs = staff&&staff.filter((item:any)=>item.code===code)
          let ans = rs.length!==0?rs[0].staff_name:''
          return ans
        }



        const getNews =(field:string, itemList:any)=>{
          const getNewsTitle = (code:string) =>{        
            const result = itemList&&itemList.filter((ls:any)=>ls.code===code);
            return  result.length!==0?result[0].title:''
            }


          const stList = JSON.parse(field)
         let list = []
           for(var i=0; i<stList.length; i++){
               list.push({
                   key:i+1,
                   value:stList[i], 
                   label: getNewsTitle(stList[i])
               })
           }
      return list;
          
          }


        const handleSelectImage =()=>{

          if(content.file_url!==''){
          setImage('')
          setContent({...content, passport:'', file_path:content.image_url})
          setPost({...post,  image_caption:content.image_caption, image_url:content.file_url, image_path:content.image_url})
    

          const inputElement = document.getElementById('image') as HTMLInputElement
          inputElement.value = ''
        setErrors({...errors, image_url:'', selectImage:''})
          let click = document.getElementById('close')?.click();
        }else{
    
          Alerts('danger', 'Please select image', 'Error')
    
    
        }
        }


        const handleSubmit =(event: FormEvent<HTMLButtonElement>, status:string)=>{
          event.preventDefault();
      
          let error = {...errors}; 
          let formIsValid = true;
      
      let msg ='This field is required';
      
      if(!post.title){
          error.title =msg;
          formIsValid = false;
      } 
      
      if(!post.author){
        error.author =msg;
        formIsValid = false;
      }
      
      if(!post.category){
        error.category =msg;
        formIsValid = false;
      }
      
      
      if(!formIsValid){
      setErrors(error) 
      }
      
      
      if(formIsValid) {
      
        
          LoadingModal()
       const fd = new FormData();
          Object.entries(post).forEach(([key, value]) => {
                  fd.append(key, String(value));
              });
      
              if(image){
                fd.append('image', image)
              }
              fd.append('status', status)

    let similar =[];
      let tags =[];
      for (var i = 0, l = content.similar_news.length; i < l; i++) {
        similar.push(content.similar_news[i].value);
      }

      for (var i = 0, l = content.tag.length; i < l; i++) {
        tags.push(content.tag[i].value);
      }
      fd.append('related', JSON.stringify(similar, null, 2))
      fd.append('tag', JSON.stringify(tags, null, 2)) 
      
      
         let url = API_URL+'/news/update/'+params.code;
        axios.post(url, fd, config)
        .then(response =>{
          if(response.data.status === 200){
      setErrors({...errors, successMessage:response.data.message})

        setTimeout(() => {
          window.location.reload()
        }, 3000);
      
                    } else{
      
      setErrors({...errors, errorMessage:response.data.message})
                    }   
                })
                .catch((error)=>{
                 ErrorModal('oops', error.message)
                }).finally(()=>{
                  closeModal()
                }) 
          }
      }

      useEffect(()=>{  
        getSimilar()
      },[news, tags]); 
      

        useEffect(()=>{  
          fetchContent() 
          fetchPost()
        },[]); 
        
  return (
    <>
   {items&&items.map((list:any, id:number)=><div key={id}>



  
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-comments me-1"></i>Update News</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item"><a href="/news">News</a></li>
                <li className="breadcrumb-item active" aria-current="page">Update</li> </ol> 
                </div> 


                  {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}


          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}


               <div className="row row-sm">

               <div className="col-xl-8"> 
               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">Modify News</div>

<div className="pull-right">
          <button type='button' onClick={(e)=>handleSubmit(e,'Published')} className='btn btn-primary btn-sm'><i className='fa fa-save'></i> Publish</button>&nbsp;
          <button type='button' onClick={(e)=>handleSubmit(e,'Draft')} className='btn btn-secondary btn-sm'><i className='fa fa-pencil'></i> Save as Draft</button>&nbsp;
          <a href='/news' className='btn btn-gray-dark btn-sm'> <i className='fa fa-arrow-left'></i> Return</a>
  </div>
                </div> 
                <div className="card-body"> 


               <div className="row"> 

               <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className={errors.title?"form-control border-danger ":"form-control"} id="title" placeholder="" name="title" value={post.title}  onChange={handleChange} /> 
       
       <label htmlFor="title" className='fw-bold'>Title</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">A news title, also known as a headline, is a brief and captivating summary of a news.</h6>
       </div>



       <div className="col-md-6">
       <div className="form-group"> 
       <label htmlFor="category" className='fw-bold mb-1'>Select Category</label> 
       <Select  options={
				category&&category.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.category_name}
				})} 
                onChange={handleSelect}  
                name="category"
                 value={{value:post.category, label:getCategory(post.category)}} 
                 styles={styles} 
                 className={errors.category?"form-control border-danger ":""}

                 />

</div><h6 className="card-subtitle  mb-3 ">Category this post belong to.</h6>
       </div>


       <div className="col-md-6">
      

      <div className="form-group"> 
      <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Author</label> 
      <Select  options={
       staff&&staff.map((node:any, id:number)=>{
         return {value:node.code, key:id, label:node.staff_name}
       })}
               onChange={handleSelect}  
               name="author"
                value={{value:post.author, label:getStaff(post.author)}}  
                styles={styles} 
                
                className={errors.author?"form-control border-danger ":""}
                />
</div>
     
<h6 className="card-subtitle  mb-3 ">Author display name and profile will appear on top and below the news.</h6>
      </div>



      <div className="col-md-6">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Upload  Picture</label> 
  <input className="form-control" type="file"  onChange={handleFile('image')} accept="image/*" name="image" id="image" /> 

  <span className="text-danger">{errors.image_url} </span>
  </div>

</div>


<div className="col-md-6">

<div className="mb-3">
  <label htmlFor="formFile1" className="form-label fw-bold">OR</label> 

  <button type='button' className='btn btn-outline-primary' data-bs-target="#addImage" data-bs-toggle="modal"><i className='fa fa-image'></i> Choose From Existing</button>
  </div>

{content.passport?
  <img id="viewPassport2" 
 className='img-thumbnail w-100' src={content.passport} alt="preview" />:content.file_path?<img id="viewPassport2" 
 className='img-thumbnail w-100' src={content.file_path} alt="preview" />:''}

</div>

<div className="col-md-12"><br/>
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text"  className="form-control" id="image_caption" placeholder="" name="image_caption" value={post.image_caption}  onChange={handleChange} /> 
       
       <label htmlFor="image_caption" className='fw-bold'>Photo caption</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">You may want to add caption to the selected image.</h6>
       </div>


<div className="col-md-12">
<label htmlFor="floatingInput" className='fw-bold mb-2'>News Body (<a href="#" onClick={handlePaste}>Paste</a>)</label> 
<div style={{ width: '100%'}}> 
                
                <TextEditor
                  height={400}
                  
                  value={post.content}
                  onEditorChange={handleEditorChange}        
                  /> 
              <span className="text-danger">{errors.content} </span>
                    </div>
</div>


<div className=" row m-2">

<div className="col-md-3">

<label htmlFor="formFile" className="form-label fw-bold">Is the news trending?</label> 

<div onClick={()=>handleSwitch('is_trending', post.is_trending)} className={`main-toggle main-toggle-secondary ${post.is_trending==='Yes'?'on':'off'}`}> 
<span></span> 
</div>

 </div> 


 <div className="col-md-3">

<label htmlFor="formFile" className="form-label fw-bold">Allow Comment?</label> 

<div onClick={()=>handleSwitch('allow_comment', post.allow_comment)} className={`main-toggle main-toggle-secondary ${post.allow_comment==='Yes'?'on':'off'}`}> 
<span></span> 
</div>

</div> 



<div className="col-md-3">

<label htmlFor="formFile" className="form-label fw-bold">Make it a news menu?</label> 

<div onClick={()=>handleSwitch('is_menu', post.is_menu)} className={`main-toggle main-toggle-secondary ${post.is_menu==='Yes'?'on':'off'}`}> 
<span></span> 
</div>

</div>


<div className="col-md-3">

<label htmlFor="formFile" className="form-label fw-bold">Is Sponsored Post?</label> 

<div onClick={()=>handleSwitch('is_sponsored', post.is_sponsored)} className={`main-toggle main-toggle-secondary ${post.is_sponsored==='Yes'?'on':'off'}`}> 
<span></span> 
</div>

</div> 

</div>
<div className="col-md-12"><br/>
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text"  className={errors.slug?"form-control border-danger ":"form-control"} id="slug" placeholder="" name="slug" value={post.slug} disabled onChange={handleChange} /> 
       
       <label htmlFor="slug" className='fw-bold'>Slug</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">The “slug” is the URL-friendly version of the title. If empty, the system will generate one automatically.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control"   id="excerpt" placeholder="" name="excerpt" value={post.excerpt} onChange={handleChange} /> 
       
       <label htmlFor="excerpt" className='fw-bold'>Excerpt</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Excerpts are optional hand-crafted summaries of your content that can be used on social media
</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="document_url" placeholder="" name="document_url" value={post.document_url} onChange={handleChange} /> 
       
       <label htmlFor="document_url" className='fw-bold'>Document Link</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Link pasted here will display at the bottom of the article for visitors to read. (pdf only)
</h6>
       </div>

       
       <div className="col-md-12">
       

       <div className="form-group"> 
       <label htmlFor="similar_news" className='fw-bold mb-1'>Select Similar Post</label> 
       <Select isMulti options={
				news&&news.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.title}
				})}  
                onChange={handleSelectEdit}  
                name="similar_news"
                 value={content.similar_news} 
                 
                 />
      </div>

       <h6 className="card-subtitle  mb-3 ">This will appear at the bottom of the article for visitors to select.
</h6>

       </div>
       

       <div className="col-md-12">
       

       <div className="form-group"> 
       <label htmlFor="tags" className='fw-bold mb-1'>Select Tags</label> 
       <Select isMulti options={
				tags&&tags.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.title}
				})}  
                onChange={handleSelectEdit}  
                name="tag"
                 value={content.tag } 
                 styles={styles} 
                 
                 />
      </div>

       <h6 className="card-subtitle  mb-3 ">News tag gives more visibilities to news .
</h6>

       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="video_url" placeholder="" name="video_url" value={post.video_url} onChange={handleChange} /> 
       
       <label htmlFor="video_url" className='fw-bold'>Video URL</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">News with video (This can be youtube or vimeo video link)
</h6>
       </div>



       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="quote" placeholder="" name="quote" value={post.quote} onChange={handleChange} /> 
       
       <label htmlFor="quote" className='fw-bold'>Quote</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Show a quote (This will appear as a block quote within the article)
</h6>
       </div>


      


               </div> 
               
               
               </div> </div> 
               </div>


               <div className="col-xl-4"> 

               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">Image Uploaded</div>

               </div> 
                <div className="card-body"> 
                  <img src={post.image_path}  alt={post.title} />

                 

               </div>

               </div>



               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">News Status</div>

               </div> 
                <div className="card-body"> 


<div className=" pull-centers">
  
  <p> <i className="fa fa-check-double"></i> Status: <b>{post.status}</b></p>

  <p> <i className="fa fa-clock"></i> Publish on:  {convertDateTime(post.created_at)}</p>
  
  <p> <i className="fa fa-clock"></i> Last Modify on: { convertDateTime(post.updated_at)}</p>

  <p> <i className="fa fa-user"></i> Created By: {getStaff(post.staff_create)}</p>

  <p> <i className="fa fa-eye"></i> View: <b>{post.view_count}</b></p>

  <p> <i className="fa fa-comment"></i> Comment: <b>0</b></p>

 <hr />
  
  <div className="row ">
    
    <div ><button type="button" onClick={DeleteModal}  className="btn btn-danger "> <i className="fa fa-trash"></i> Delete this News</button>
    
    
    </div></div>
    </div>

               </div>

               </div>
               </div>
               </div>




               <div className="modal fade" id="addImage" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-xl" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Choose Image</h6>
              <button type="button" id='close' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <div className="row"> 
    
       
    <div className="col-lg-4 col-xl-3 col-md-12 col-sm-12"> 
    <div className="cards"> 
    <div className="card-body p-0"> 

    <h4 className='text-primary text-center'> 
                  <i className="fa fa-cubes"></i> Category</h4>
                  <hr/>
    <div className="p-3 h-600">

       <ul className="nav nav-pills main-nav-column"> 
       
        {category&&category.map((list:any, id:number)=>
       <li className="nav-item " key={id}><a className= {content.cat_code===list.code?'nav-link thumb active':'nav-link thumb'} href="#" onClick={()=>setContent({...content, cat_code:list.code})}> {list.category_name} </a></li> 
       )}

        </ul> 
       </div> 
       
       
       
       </div> </div> </div>



      <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">


      <h4 className='text-primary text-center'> 
                  <i className="fa fa-image"></i> News   Images</h4>
                  <hr/>

      <div className="p-3 h-600">


<div className="row">

  {news&&news.filter((item:any)=>item.category===content.cat_code).map((list:any, id:number)=>
<div className="col-sm-3 col-md-4 col-lg-6 col-xl-3 pointer mb-2" key={id} onClick={()=>setContent({...content, file_url:list.image_url,  image_code:list.code, image_url: list.image_path,image_caption:list.image_caption})}>

<img src={CLOUD_IMAGES+'/w_300,h_200/'+list.image_url} alt={list.category} title={list.title} className= {content.image_code===list.code?'border-primary img-thumbnail':'br-5'} />
</div>
)}

{news.filter((item:any)=>item.category===content.cat_code).length===0?
<p className='text-center fw-bold '>No image found, please select category to choose.</p>:[]}




</div>


      </div>

        </div> 

        </div> 

    <div className="modal-footer ">

<button type="button"  data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" onClick={handleSelectImage} className="btn btn-success"  > <i className="fa fa-save"></i> Select </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 
    </div>)}
    </>
  )
}

export default UpdateNews