import { useState, ChangeEvent, FormEvent, useEffect, useCallback} from 'react'
import axios from 'axios'
import Select from 'react-select'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_URL, config } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';


const Category =()=> {

  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
let initial ={
  category_name:'',
  is_menu:'No',
  is_submenu:'No',
  parent_category:{ value: '', label: '' },
  filterText:'',
  priority:1,
}
const [director, setDirector] = useState({
  isSN:false,
  isName:false,
  isMenu:false,
  isSubmenu:false,
  isParent:false,
  isTotal:false,
  isStatus:false,
  isPriority:false,
  isDate:true,
  isAction:true
});
  const [items, setItems] = useState(initial);
  const [update, setUpdate] = useState({
    category_name:'',
  is_menu:'Yes',
  is_submenu:'No',
  parent:'',
  priority:'',
status:'' as any
});
  const [errors, setErrors] = useState({
    category_name:'',
    parent_category:'',
    successMessage:'',
    errorMessage:''
  });


const handleReset =()=>{
  setItems(initial)
}

const handleResetColumn =()=>{

  setDirector({isSN:false,
    isName:false,
    isMenu:false,
    isSubmenu:false,
    isParent:false,
    isTotal:false,
    isStatus:false,
  isPriority:false,
  isDate:false,
    isAction:false})
}

  const handleSelect = (option:any, action:any)=>{
    setItems({...items, [action.name]:option});
      setErrors({...errors, [action.name]:''})
       }


       const handleSwitch =(name:string, value:string)=>{
          value = value==='Yes'?'No':'Yes'
        setItems({...items, [name]:value});
     }


       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        setItems({...items, [name]:value});
       setErrors({...errors, [name]:''});
     }


     const handleSelectEdit = (option:any, action:any)=>{
      setUpdate({...update, [action.name]:option.value});
        setErrors({...errors, [action.name]:''})
         }

     const handleSwitchEdit =(name:string, value:string)=>{
      value = value==='Yes'?'No':'Yes'
      setUpdate({...update, [name]:value});
      }

     const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      setUpdate({...update, [name]:value});
     setErrors({...errors, [name]:''});
   }

     const handleClear =()=>{
      setItems({...items, filterText:''});
      setFilterContent(content)
  }

   const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setItems({...items, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.category_name.toLowerCase().includes(value.toLowerCase()) || 
              item.is_menu.toLowerCase().includes(value.toLowerCase()) ||
              item.is_submenu.toLowerCase().includes(value.toLowerCase()) ||
              item.status.toLowerCase().includes(value.toLowerCase()) ||
              item.parent.toLowerCase().includes(value.toLowerCase())
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }

 
   const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!update.category_name){
    error.category_name =msg;
    formIsValid = false;
} 


if(update.is_submenu==='Yes' && !update.parent){
  error.parent_category=msg;
  formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

  let click = document.getElementById('closeedit')?.click();
    LoadingModal()
 const fd = new FormData();
    Object.entries(update).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        let parent = update.is_submenu==='Yes'?update.parent:'root'
        fd.append('parent', parent);

   let url = API_URL+'/category/update';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
  

              } else{

setErrors({...errors, errorMessage:response.data.message})
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            closeModal()
            fetchContent()
          }) 
    }
}

     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!items.category_name){
      error.category_name =msg;
      formIsValid = false;
  } 


  if(items.is_submenu==='Yes' && !items.parent_category.value){
    error.parent_category=msg;
    formIsValid = false;
} 


const newContent = content.map((item:any)=>{
    if(items.category_name===item.category_name){
      error.category_name='Category name already exist';
    formIsValid = false;
    }
    })

  if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  
    let click = document.getElementById('close')?.click();
      LoadingModal()
   const fd = new FormData();
      Object.entries(items).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  
          let parent = items.is_submenu==='Yes'?items.parent_category.value:'root'
          fd.append('parent', parent);

     let url = API_URL+'/category/create';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
    
  handleReset()

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
              fetchContent()
            }) 
      }
  }


const fetchContent = async()=>{
  setLoading(true)
  let url = API_URL+'/categories/all'
    await  axios.get(url,  config).then((result:any)=>{
      if(Array.isArray(result.data.data)){
      setContent(result.data.data)
      setFilterContent(result.data.data)
      }
      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      }).finally(()=>{
        setLoading(false)
      })
    }

    const DisplaySelect=({code}:{code:string})=>{	  
      let options = get_options();
     return (
         <div>
          {options.map((data:any) =>data.code===code?data.category_name:'') }
          </div> 
     )
          
  }

  const getEdit =(row:any)=>{

    let click = document.getElementById('openCategory')?.click();

    setUpdate(row)


  }

const getCategory =(code:string)=>{
  let rs = content.filter((item:any)=>item.code===code)
  let ans = rs.length!==0?rs[0].category_name:''
  return ans
}

    const get_options=(parent="root", indent="")=> {
			var result:any= []
			content&&content.map((cat:any)=>{
				if(cat.parent===parent){

					result.push({code:cat.code, category_name:indent+cat.category_name})

					result = result.concat(get_options(cat.code, indent+cat.category_name+"=>\t"))
				}
			})
			return result;
		  }

     const DisplayParent=()=>{	  
			let details = get_options();
			return ( <Select options={
				details&&details.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.category_name}
				})} 
                
	onChange={handleSelect}  
  name="parent_category" 
  className={errors.parent_category ? 'form-control border-danger' : ''} value={items.parent_category} /> 
	)
}

const DisplayParentEdit=()=>{	  
  let details = get_options();
  return ( <Select options={
    details&&details.map((node:any, id:number)=>{
      return {value:node.code, key:id, label:node.category_name}
    })} 
            
onChange={handleSelectEdit}  
name="parent" 
className={errors.parent_category ? 'form-control border-danger' : ''} value={{value:update.parent, label:getCategory(update.parent)}} /> 
)

}

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/category/delete/one/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const TableData =()=>{

            
    const PaginationWrapper =(props:any)=>{
    
        const handleRowsPerPage = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
            [props.currentPage, props.onChangeRowsPerPage],
        );
        const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
        [props.currentPage, props.onChangeRowsPerPage],
        )
       
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
        const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
        const lastIndex = props.currentPage * props.rowsPerPage;
        const firstIndex = lastIndex - props.rowsPerPage + 1;
    
    
            const range =
            props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
    
            `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
    
        
       
        const Pagination = () => {
           
            const pageSize = props.rowsPerPage;
            const totalCount = content.length
            const siblingCount = 1
            const currentPage = props.currentPage
            
            const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
            
            if (currentPage === 0 ) {
              return null;
            }
            
            let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
            return (
            <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Prev</a>
                </li>
                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                </li>
            
              </ul> </nav>
            );
            
                }
    
              
  
              
    
     return <div className="row mt-3">
                        
    <div className="col-md-5 ">
    <div className=" ml-3" >
    
        &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
            <option value="10"> 10</option>
            <option value="20"> 20</option>
            <option value="30"> 30</option>
            <option value="40"> 40</option>
            <option value="50"> 50</option>
            <option value={content.length}> All</option>
        </select> &nbsp;&nbsp;
         {range}  </div>
    </div>
      
    <div className="col-md-7  justify-content-end d-flex">
       <Pagination  />
        </div>
        
    </div>}
    
    
                const columns = [
    
                    {
                        name: 'SN',
                        cell: (row:any, index:number) => index+1, 
                      omit: director.isSN,
                        width:'60px'
                    },
                    {
                      name: 'Name',
                      cell: (row:any) => row.category_name, 
                      sortable: true,
                      omit: director.isName,
                      reorder:true,
                      width:'200px'
                  },
                    {
                        name: 'Is Menu',
                        selector: (row:any) =>row.is_menu,
                        sortable: true,
                        reorder:true,
                        omit: director.isMenu,
                        width:'100px'
                    },
                    {
                        name: 'Is Submenu',
                        selector: (row:any) => row.is_submenu,
                        sortable: true,
                        reorder:true,
                        omit: director.isSubmenu,
                        width:'100px'
                    },
                    {
                      name: 'Parent',
                      selector: (row:any) =><DisplaySelect code={row.parent} />,
                      sortable: true,
                      reorder:true,
                      omit: director.isParent,
                      width:'150px'
                  },
                  {
                    name: 'Priority',
                    selector: (row:any) =>row.priority,
                    sortable: true,
                    reorder:true,
                    omit: director.isPriority,
                    width:'100px'
                },
                    {
                      name: 'Total',
                      selector: (row:any) => row.total_item,
                      sortable: true,
                      reorder:true,
                      omit: director.isTotal,
                      width:'100px'
                  },
                
                    {
                        name: 'Status',
                        cell: (row:any) => <span className={`badge  ${row.status==='Active'?'badge-secondary':'badge-primary'} me-1`}>{row.status}</span>,
                        omit: director.isStatus,
                      width:'100px'
                    },
                    {
                      name: 'Created At',
                      selector: (row:any) => row.created_at,
                      sortable: true,
                      reorder:true,
                      omit: director.isDate,
                      width:'100px'
                  },
                    {
                        name: 'Action',
                        cell: (row:any, index:any, column:any, id:any) => <div >  
                           
                            <button type='button' data-bs-toggle="tooltip" title="" data-bs-original-title="Edit Category" onClick={()=>getEdit(row)} className="btn btn-info-transparent btn-sm"   ><i className='fa fa-pencil'></i> Modify</button>
    &nbsp;
   
                 <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
                        
                        </div>,
                            left:true,
                            omit: director.isAction,
                            width:'200px'
                    }
                ];
                
            
            
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    
            
    useEffect(()=>{  
      fetchContent()   
    },[]); 
  return (
    <>
<a href="#" id='openCategory' data-bs-target="#updateCategory" data-bs-toggle="modal"></a>

<div className="modal fade" id="updateCategory" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-dialog-centered" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Update Category</h6>
              <button type="button" id='closeedit' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <form action="#" className='needs-validations '> 
    <div className="row"> 
    <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.category_name?'border-danger':''}`}> 

       <input type="text" name="category_name" value={update.category_name} onChange={handleChangeEdit} className="form-control" id="category_name" placeholder="" /> 
       
       <label htmlFor="category_name" className='fw-bold'>Category Name</label> 
       </div> 
       <div className="text-danger">{errors.category_name} </div>
       </div> 
       

       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f `}> 

       <input type="number" name="priority" value={update.priority} onChange={handleChangeEdit} className="form-control" id="priority" placeholder="" /> 
       
       <label htmlFor="priority" className='fw-bold'>Priority</label> 
       </div> 
       </div> 
       
       <div className="col-md-12"> 

       <div className="form-group"> 
       <label className="custom-switch" > 

       <input type="radio" name="is_menu" className="custom-switch-input" onClick={()=>handleSwitchEdit('is_menu', update.is_menu)} checked={update.is_menu==='Yes'?true:false}  value={update.is_menu} /> 
       <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 
       <span className="fs-14  fw-bold me-2"> Make Category Appear on  Menu</span>
       </label>
        </div>


        <div className="form-group"> 
       <label className="custom-switch"> 

       <input type="radio" name="is_submenu" className="custom-switch-input"  onClick={()=>handleSwitchEdit('is_submenu', update.is_submenu)} checked={update.is_submenu==='Yes'?true:false}  value={update.is_submenu} /> 
       <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 

       <span className="fs-14  fw-bold me-2"> Make Category a Sub-Menu ( <span className='text-warning fs-12'> You have to select parent category </span>)</span>
       </label>
        </div>

        </div> 


{update.is_submenu==='Yes'?
        <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Parent Category</label> 

       <DisplayParentEdit />
      </div>

       </div>:''}



       <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Status</label> 

       <Select  options={[
                    { value: 'Active', label: 'Active' },
                    { value: 'Inactive', label: 'Inactive'}
                                ]} 
                onChange={handleSelectEdit}  
                name="status"
                 value={{value:update.status, label:update.status}} 
                 
                 />

      </div>

       </div>

        </div> 
        </form>


    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" className="btn btn-success" onClick={handleUpdate} > <i className="fa fa-save"></i> Update </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 



    
    <div className="modal fade" id="addCategory" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-dialog-centered" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Add New Category</h6>
              <button type="button" id='close' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <form action="#" className='needs-validations '> 
    <div className="row"> 
    <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.category_name?'border-danger':''}`}> 

       <input type="text" name="category_name" value={items.category_name} onChange={handleChange} className="form-control" id="category_name" placeholder="" /> 
       
       <label htmlFor="category_name" className='fw-bold'>Category Name</label> 
       </div> 
       <div className="text-danger">{errors.category_name} </div>
       </div> 
       

       
       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f `}> 

       <input type="number" name="priority" value={items.priority} onChange={handleChange} className="form-control" id="priority" placeholder="" /> 
       
       <label htmlFor="priority" className='fw-bold'>Priority</label> 
       </div> 
       </div> 

       <div className="col-md-12"> 

       <div className="form-group"> 
       <label className="custom-switch" > 

       <input type="radio" name="is_menu" className="custom-switch-input" onClick={()=>handleSwitch('is_menu', items.is_menu)} checked={items.is_menu==='Yes'?true:false}  value={items.is_menu} /> 
       <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 
       <span className="fs-14  fw-bold me-2"> Make Category Appear on  Menu</span>
       </label>
        </div>


        <div className="form-group"> 
       <label className="custom-switch"> 

       <input type="radio" name="is_submenu" className="custom-switch-input"  onClick={()=>handleSwitch('is_submenu', items.is_submenu)} checked={items.is_submenu==='Yes'?true:false}  value={items.is_submenu} /> 
       <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 

       <span className="fs-14  fw-bold me-2"> Make Category a Sub-Menu ( <span className='text-warning fs-12'> You have to select parent category </span>)</span>
       </label>
        </div>

        </div> 


{items.is_submenu==='Yes'?
        <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Parent Category</label> 

       <DisplayParent />
      </div>

       </div>:''}


        </div> 
        </form>


    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" className="btn btn-success" onClick={handleSubmit} > <i className="fa fa-save"></i> Save </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 


       


              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-cubes me-1"></i> Category</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="#">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Category</li> </ol> 
                </div> 


                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Categories</div>

                <button type='button'  data-bs-target="#addCategory" data-bs-toggle="modal"  className='btn btn-primary'> <i className='fa fa-plus'></i> Add Category</button>
          
                </div> 
                <div className="card-body"> 



                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
 


  <div className=""> 

                    <button type='button' className='btn btn-secondary' data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ><i className="fa fa-filter text-gray-500 fs-18"></i> Column Visibility <i className='fa fa-arrow-down'></i></button>
  
   <ul className="dropdown-menu top " role="menu" > 
   
   <li><a  className={director.isSN?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isSN:!director.isAction})}>SN</a></li> 

   <li><a className={director.isName?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Name</a></li> 

   <li><a className={director.isMenu?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isMenu:!director.isMenu})}>Is Menu</a></li> 

   <li><a className={director.isSubmenu?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isSubmenu:!director.isAction})}>Is Submenu</a></li> 

   <li><a className={director.isParent?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isParent:!director.isParent})}>Parent Category</a></li> 


   <li><a className={director.isPriority?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isPriority:!director.isPriority})}>Priority</a></li>

   
   <li><a className={director.isTotal?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isTotal:!director.isTotal})}>Total Items</a></li> 

   <li><a className={director.isStatus?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isStatus:!director.isStatus})}>Status</a></li> 


   <li><a className={director.isDate?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isDate:!director.isDate})}>Created At</a></li> 


   <li> <a className={director.isAction?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a></li> 
   <div className="dropdown-divider"></div> 
   <li><a href="#" onClick={handleResetColumn}>Reset Column</a></li> 
   </ul>
  
  
  </div>

                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={items.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>
    </div>
</div>


               <TableData />

               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default Category