import { useState, ChangeEvent, useEffect, useCallback} from 'react'

import { useSelector } from 'react-redux'
import Select from 'react-select'
import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_URL, API_IMAGES, config, CLOUD_IMAGES, SITE_URL } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';


const News =()=> {

  const staff  = useSelector((state:any) => state.rootReducer.staff);
  const category  = useSelector((state:any) => state.rootReducer.category);
  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
  const [search, setSearch] = useState({
    filterText:'',
    category:{label:'', value:''},
    staff:{label:'', value:''},
    author:{label:'', value:''},
    status:{label:'', value:''},
   title:'',
   date_posted:''

  });


  const handleReset =()=>{
    setSearch({
      filterText:'',
    category:{label:'', value:''},
    staff:{label:'', value:''},
    author:{label:'', value:''},
    status:{label:'', value:''},
   title:'',
   date_posted:''
    })
    fetchContent()
  }
  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.title.toLowerCase().includes(value.toLowerCase()) || 
            item.category_name.toLowerCase().includes(value.toLowerCase()) ||
            item.status.toLowerCase().includes(value.toLowerCase()) ||
            item.is_menu.toLowerCase().includes(value.toLowerCase()) ||
            item.staff_name.toLowerCase().includes(value.toLowerCase())
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }
  const [director, setDirector] = useState({
    isSN:false,
    isTitle:false,
    isPhoto:false,
    isCategory:false,
    isTrending:true,
    isSponsored:true,
    isMenu:true,
    isComment:true,
    isStatus:true,
    isAction:false,
    isView:false,
    isAuthor:true,
  });

  const handleResetColumn =()=>{

    setDirector({
      isSN:false,
    isTitle:false,
    isPhoto:false,
    isCategory:false,
    isTrending:true,
    isSponsored:true,
    isMenu:true,
    isComment:true,
    isStatus:true,
    isAction:false,
    isView:false,
    isAuthor:true,})
  }
  const handleClear =()=>{
    setSearch({...search, filterText:''});
    setFilterContent(content)
}


const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
  let {name, value} = event.target;	

  setSearch({...search, [name]:value});
}

  const handleSelect = (option:any, action:any)=>{
    setSearch({...search, [action.name]:option});

       }

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/news/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const TableData =()=>{

    const paginationComponentOptions = {
      rowsPerPage: 100,
    };
    const PaginationWrapper =(props:any)=>{
    
        const handleRowsPerPage = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
            [props.currentPage, props.onChangeRowsPerPage],
        );
      
       
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
        const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
        const lastIndex = props.currentPage * props.rowsPerPage;
        const firstIndex = lastIndex - props.rowsPerPage + 1;
    
    
            const range =
            props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
    
            `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
    
           
       
        const Pagination = () => {
           
            const pageSize = props.rowsPerPage;
            const totalCount = content.length
            const siblingCount = 1
            const currentPage = props.currentPage
            
            const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
            
            if (currentPage === 0 ) {
              return null;
            }
            
            let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
            return (
            <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                </li>
                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                </li>
            
              </ul> </nav>
            );
            
                }
    
              
  
   return <div className="row mt-3">
                      
   <div className="col-md-5 ">
   <div className=" ml-3" >
   
       &nbsp; <select name="rowsPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
           <option value="100"> 100</option>
           <option value="200"> 200</option>
           <option value="300"> 300</option>
           <option value="400"> 400</option>
           <option value="500"> 500</option>
           <option value={content.length}> All</option>
       </select> &nbsp;&nbsp;
        {range}  </div>
   </div>
     
   <div className="col-md-7  justify-content-end d-flex">
      <Pagination  />
       </div>
       
   </div>}
    
    
                const columns = [
                  {
                    name: 'SN',
                    cell: (row:any, index:number) => index+1, 

                    omit: director.isSN,
                    width:'60px'
                },
                     {
                        name: 'Image',
                        cell: (row:any) => <div className="d-inline-block align-middle">
                        <img src={API_IMAGES+row.image_path} alt="news image" className=" img-40" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =API_IMAGES+'/default.jpg'}} />
                    </div>, 
                        sortable: true,
                        reorder:true,

                      omit: director.isPhoto,
                        width:'100px'
                    }, 
                    {
                      name: 'title',
                      cell: (row:any) => <a href={SITE_URL+'/post/'+row.slug} target='_blank'>{row.title}</a>, 
                      sortable: true,
                      reorder:true,

                      omit: director.isTitle,
                      wordwrap:true
                  },
                    {
                        name: 'Category',
                        selector: (row:any) =>row.category_name,
                        sortable: true,
                        reorder:true,

                      omit: director.isCategory,
                        width:'150px'
                    },
                    {
                      name: 'Author',
                      selector: (row:any) =>row.staff_name,
                      sortable: true,
                      reorder:true,

                    omit: director.isAuthor,
                      width:'150px'
                  },
                    {
                      name: 'Is Trending',
                      selector: (row:any) =>row.is_trending,
                      sortable: true,
                      reorder:true,

                      omit: director.isTrending,
                      width:'100px'
                  },
                  {
                    name: 'Is Sponsored',
                    selector: (row:any) =>row.is_sponsored,
                    sortable: true,
                    reorder:true,

                    omit: director.isSponsored,
                    width:'100px'
                },
                {
                  name: 'Is Menu',
                  selector: (row:any) =>row.is_menu,
                  sortable: true,
                  reorder:true,

                  omit: director.isMenu,
                  width:'100px'
              },
              {
                name: 'Allow Comment',
                selector: (row:any) =>row.allow_comment,
                sortable: true,
                reorder:true,

                omit: director.isComment,
                width:'100px'
            },
            {
              name: 'View',
              selector: (row:any) =>row.view_count,
              sortable: true,
              reorder:true,

              omit: director.isView,
              width:'100px'
          },
            {
              name: 'Status',
              cell: (row:any) => <span className={`badge  ${row.status==='Published'?'badge-secondary':'badge-danger'} me-1`}>{row.status}</span>,

              omit: director.isStatus,
            width:'100px'
          },{
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) =><div >  
               
               <a href={"/news/edit/"+row.code}  className="btn btn-info-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit News"  ><i className='fa fa-pencil'></i> Modify</a>
&nbsp;

    <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
           
           </div>,
                left:true,

                omit: director.isAction,
                width:'200px'
        },
                ];
                
            
            
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationPerPage={100}
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    

const fetchContent = async()=>{
  setLoading(true)
let url = API_URL+'/news/display/all'
const fd = new FormData();
fd.append('category', search.category.value)
fd.append('author', search.author.value)
fd.append('title', search.title)
fd.append('staff', search.staff.value)
fd.append('status', search.status.value)
fd.append('date_post', search.date_posted)


  await  axios.post(url, fd, config).then((result:any)=>{

    if(Array.isArray(result.data.data)){
    setContent(result.data.data)
    setFilterContent(result.data.data)
    }
    })
    .catch((error)=>{
     // ErrorModal('oops', error.message)
    }).finally(()=>{
      setLoading(false)
    })
  }
  
  useEffect(()=>{  
    fetchContent()   
  },[]);


  return (
    <>
    

              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-comment me-1"></i> News</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">News</li> </ol> 
                </div> 



                <div className="row row-sm">

<div className="col-xl-12"> 
<div className="card"> 
<div className="card-header  d-flex justify-content-between"> 
<div className="card-title text-primary">Search News</div>


 </div> 
 <div className="card-body"> 

<div className="row">

<div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Category</label> 
       <Select  options={
				category&&category.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.category_name}
				})} 
                onChange={handleSelect}  
                name="category"
                 value={search.category} 
                 
                 />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Author</label> 
       <Select  options={
				staff&&staff.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.staff_name}
				})}
                onChange={handleSelect}  
                name="author"
                 value={search.author} 
                 
                 />

</div>
       </div>

       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Status</label> 
       <Select  options={[
                    { value: 'Published', label: 'Published' },
                    { value: 'Draft', label: 'Draft'}
                                ]} 
                onChange={handleSelect}  
                name="status"
                 value={search.status} 
                 
                 />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Date Posted</label> 
       <input type="date" className='form-control' value={search.date_posted} name='date_posted' onChange={handleChange} />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label  className='fw-bold mb-1'>Search By Title</label> 
       <input type="text" className='form-control' value={search.title} name='title'  onChange={handleChange}  />

</div>
       </div>



       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Staff Posted</label> 
       <Select  options={
				staff&&staff.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.staff_name}
				})}
                onChange={handleSelect}  
                name="staff"
                 value={search.staff} 
                 
                 />

</div>
       </div>


</div>
<div className="card-footer ">
<div className="pull-right">

<button type='button' onClick={handleReset} className='btn btn-warning'><i className='fa fa-refresh'></i> Reset </button>&nbsp;

  <button type='button' onClick={fetchContent} className='btn btn-secondary'><i className='fa fa-search'></i> Search </button>
  </div>
</div>



</div> </div> 
</div>

</div>





               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All News</div>

                <a href='/news/add'   className='btn btn-primary'> <i className='fa fa-plus'></i> Add News</a>
          
                </div> 
                <div className="card-body"> 
              
                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
  <div className=""> 

                    <button type='button' className='btn btn-secondary' data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ><i className="fa fa-filter text-gray-500 fs-18"></i> Column Visibility <i className='fa fa-arrow-down'></i></button>
  
   <ul className="dropdown-menu top " role="menu" > 
   
   <li><a  className={director.isSN?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isSN:!director.isAction})}>SN</a></li> 


   <li><a className={director.isPhoto?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isPhoto:!director.isPhoto})}>Photo</a></li> 

   <li><a className={director.isTitle?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isTitle:!director.isTitle})}>Title</a></li> 


   <li><a className={director.isCategory?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isCategory:!director.isCategory})}>Category</a></li> 

   <li><a className={director.isAuthor?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isAuthor:!director.isAuthor})}>Author</a></li> 

   <li><a className={director.isTrending?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isTrending:!director.isTrending})}>Is Trending</a></li>

   <li><a className={director.isSponsored?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isSponsored:!director.isSponsored})}>Is Sponsored</a></li> 

   <li><a className={director.isMenu?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isMenu:!director.isMenu})}>Is Menu</a></li> 


   <li><a className={director.isComment?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isComment:!director.isComment})}>Allow Comment</a></li> 

   <li><a className={director.isView?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isView:!director.isView})}>Total View</a></li> 

   <li><a className={director.isStatus?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isStatus:!director.isStatus})}>Status</a></li> 

   <li> <a className={director.isAction?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a></li> 
   <div className="dropdown-divider"></div> 
   <li><a href="#" onClick={handleResetColumn}>Reset Column</a></li> 
   </ul>
  
  
  </div>

                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={content.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>

       
      

    </div>
</div>
              
               <TableData />


               
               
               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default News